.ads-pages-wrapper {
    margin-top: 35px;

}

.ads-page-wrapper {
    display: flex;
    flex-wrap: wrap;
    // display: grid;
    // gap: 20px;
    // grid-template-columns: repeat(auto-fill, minmax(650px, 1fr));
}

.ads-page-form {
    width: 100%;
    display: grid;

    gap: 20px;

    grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
}



.ads-page-collection-head-data,
.ads-page-collection-info-data {
    min-width: 420px;
    // width: calc(50% - 15px);
    height: fit-content;
    border-radius: 8px;
    border: 1px solid #E9ECEF;
    padding: 30px;
}



.ads-upload {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #E9ECEF;
    padding: 30px;

}

.ads-collection-head-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.upload-head {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}

.uploads-content-wrapper {
    display: flex;
    flex-direction: column;
}

.upload-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    border-radius: 8px;
    border: 1px dashed #E9ECEF;
    padding: 15px;
}

.uploads-icon {
    font-size: 24px;
    color: #cecfd0f3;
}

.uploads-icon-head {
    font-size: 24px;
    margin-top: 10px;
}

.uploads-icon-description {
    font-size: 12.5px;
    // color: #cecfd0f3;
    margin-top: 10px;
    text-align: center;
}

.ads-button {
    // width: 90%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.for-value {
    display: flex;
    gap: 10px;
    align-items: center;
}