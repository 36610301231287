.player-banner {
    position: relative;
    background-color: #000000;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: fit-content;
    min-height: 300px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: left;

}

.player-banner__content {
    position: absolute;
    bottom: 50px;
    left: 50%;
    right: 0;
    transform: translateX(-50%);

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5.458px;

}

.banner__content_wrapper {
    padding: 15px;

    & .player-banner__content__title {
        color: #FEFEFE;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
    }

    & .player-banner__content__description {
        margin-top: 5px;
        color: #FEFEFE;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
    }
}