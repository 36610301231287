.page {
    width: 100%;
    height: 100vh;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .container {
        width: 400px;
        background-color: rgba(235, 234, 234, 0.258);
        padding: 40px;
        border-radius: 10px;

        h2 {

            margin-bottom: 10px;
            font-size: 25px;
            font-weight: bold;
            text-align: center;
        }

        h5 {
            margin-bottom: 10px;
            text-align: center;
            font-size: 14px;
        }

        .error {
            // margin-top: 3px;
            margin: 10px auto;
            width: 100%;
            height: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .content-provider-block {
        // position: absolute;
        // width: 100%;
        // left: 0;
        // bottom: 0;
        padding: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .wrapper {
            width: max-content;
            padding: 10px;
            cursor: pointer;
            transition: 0.2s;
            border-radius: 5px;

            &:hover {
                background-color: rgba(235, 234, 234, 0.258);
            }
        }
    }


}

.language-selector {
    width: 90px;
    position: absolute;
    top: 7.5px;
    right: 7.5px;
}

.forgot-password-wrapper {
    text-align: center;
    margin: 20px auto;
    height: 0;

    &>div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    &>span {
        font-size: 14px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

.page-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;


}

.privacy-policy {
    padding: 20px;
    cursor: pointer;
    transition: 0.2s;
    border-radius: 5px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;

    &>span {
        font-size: 18px;
        text-align: center;
        text-decoration: underline;
    }

    &:hover {
        background-color: rgba(235, 234, 234, 0.258);
    }
}


.app-logo {
    margin-top: 25px;

    &>img {
        max-height: 110px;
    }
}