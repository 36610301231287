$active-color: var(--main-active-color);

.ant-btn-primary {
    background-color: var(--main-active-color);

    &:hover {
        opacity: 0.7;
        background-color: var(--main-active-color) !important;
    }
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--main-active-color) !important;
}

.ant-tabs-ink-bar {
    background-color: var(--main-active-color) !important;
}

.ant-switch.ant-switch-checked {
    background-color: var(--main-active-color) !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: var(--main-active-color) !important;
    background-color: var(--main-active-color) !important;
}

.ant-typography-copy {
    color: var(--main-active-color) !important;
}

.anticon {
    vertical-align: 1px;
}

.ant-layout {
    background-color: #fbfdff;
}

.ant-layout-header {
    padding-inline: 40px;
}

.ant-input:focus,
.ant-input:hover {
    border-color: var(--main-active-color) !important;
}

.antd-sidebar-menu {
    background-color: #fbfdff;

    .ant-menu-submenu-title,
    .ant-menu-item {
        width: 100%;
        margin: 0;
        border-radius: 0;
    }

    .ant-menu-submenu-selected>.ant-menu-submenu-title {
        color: var(--main-active-color) !important;
        opacity: 0.8;
    }

    .ant-menu-item-selected {
        color: white !important;
        background-color: var(--main-active-color) !important;
    }

    // .ant-menu-item:focus {
    //     // background-color: $active-color !important;
    // }
    // .ant-menu-item-selected {
    //     background-color: $active-color !important;
    // }

    // .ant-menu-submenu-title:focus {
    //     background-color: $active-color !important;
    // }
}

.antd-sidebar-menu {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// .antd-sidebar-menu {
//     .ant-menu span {
//         color: $link-color !important;
//     }

//     .ant-menu-item-active {
//         color: $active-color !important;
//         span {
//             color: $active-color !important;
//         }

//         .ant-menu-item-icon {
//             color: $active-color !important;
//         }
//     }

//     .ant-menu-item-active :hover {
//         color: $active-color !important;
//         span {
//             color: $active-color !important;
//         }
//     }

//     .ant-menu-submenu-title:hover {
//         color: $active-color !important;
//         span {
//             color: $active-color !important;
//         }
//         .ant-menu-submenu-arrow {
//             color: $active-color !important;
//         }
//     }

//     .ant-menu-submenu-selected {
//         color: $active-color !important;
//         span {
//             color: $active-color !important;
//         }
//         .ant-menu-submenu-arrow {
//             color: $active-color !important;
//         }
//     }

//     .ant-menu-submenu-title {
//         color: $link-color !important;

//         span {
//             color: $link-color !important;
//         }
//         .ant-menu-submenu-arrow {
//             color: $link-color !important;
//         }
//     }

//     .ant-menu-item-active:active {
//         // background-color: #1a1a1a;

//         span {
//             color: $active-color !important;
//         }
//     }

//     .ant-menu-item-selected {
//         background-color: $active-background !important;

//         span {
//             color: $active-color !important;
//         }
//         a {
//             color: $active-color !important;
//         }
//     }

//     .ant-menu-submenu-title {
//         color: $link-color !important;

//         .ant-menu-submenu-arrow {
//             color: $link-color !important;
//         }
//     }

//     .ant-menu-sub {
//         background-color: transparent !important;
//         .ant-menu-submenu-arrow :hover {
//             color: $active-color !important;
//         }
//     }

//     .ant-menu-item-selected :hover {
//         color: $active-color !important;
//         span {
//             color: $active-color !important;
//         }
//     }

//     .menu_iteam {
//         color: $link-color !important;
//     }

//     .menu_item_link {
//         color: $link-color !important;
//     }
// }

.ant-pagination-item a {
    display: inline !important;
}

.ant-table-pagination {
    gap: 10px;
}

.ant-btn-dangerous {
    &:hover {
        background-color: #f96565 !important;
    }
}

.ant-btn-dangerous {
    &:hover {
        color: white !important;
    }
}

.red-row {
    background-color: #ffccc7 !important;

    &:hover {
        background-color: #f9656564 !important;
    }
}

.table-green-row {
    background-color: #9dff9444 !important;
}

.clickable-row {
    cursor: pointer;
}

.grid-input {
    display: grid;
    grid-gap: 0px 30px;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}

label {
    // margin-bottom: 0px !important;
    white-space: nowrap;
}

.ant-select-selection-item {
    max-width: 100% !important;
}

.ant-menu-title-content {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}