.volume-preview {
    position: relative;

    background-color: #000000;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: fit-content;
    min-height: 300px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: left;
}

.volume-slider {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 40.604px;
    height: 172.291px;
    flex-shrink: 0;
    border-radius: 17.558px;
    border: 0.732px solid rgba(255, 255, 255, 0.50);
    background: radial-gradient(70.71% 70.71% at 50% 50%, rgba(118, 146, 255, 0.00) 80.55%, rgba(122, 150, 255, 0.02) 100%), radial-gradient(70.71% 70.71% at 50% 50%, rgba(226, 232, 255, 0.00) 33.78%, rgba(226, 232, 255, 0.03) 100%), linear-gradient(0deg, rgba(237, 229, 255, 0.12) 0%, rgba(237, 229, 255, 0.12) 100%), #242424;

}

.volume-slider__bar {
    position: relative;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 100px;
    border-radius: 17.558px;
    background: #2C2C2C;
}

.volume-slider__fill {
    position: absolute;
    bottom: 0%;
    left: 0;
    width: inherit;
    height: 50%;
    background-color: #fff;
    // width: 100%;
    // height: 0;
    // background: #767676;
    border-radius: 17.558px;
    // transition: height 0.3s;
}

.volume-slider-ads-place {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translate(50%, 0);
    width: inherit;
    height: 35.117px;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

}