.card {
    width: 90%;
    height: 280px;
    // background-color: var(--main-active-color);
    background-color: #000;
    color: white;
    border-radius: 15px;
    margin: 10px auto 50px;
    text-align: center;
    padding: 20px;
    position: relative;
}

.logo {
    padding: 5px;
    position: absolute;
    left: 16px;
    top: 20px;
    width: 100px;
    // height: 50px;
    max-width: fit-content;
    // max-height: 50px;
    height: auto;
    display: block;

    // background-color: white;
    & img {
        width: 100%;
        height: auto;
    }

}

.card h1 {
    font-size: 28px;
    margin: 60px 0 0;
    padding: 0;
    color: #fff;

}

.card p {
    display: block;
    width: 44%;
    max-width: 20rem;
    text-align: center;
    margin: 23px auto;
    color: #fff;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;

}

.card .code,
.card .duration,
.card .count {
    position: absolute;
    font-size: 14px;
    color: #fff;
}

.card .code {
    bottom: 20px;
    left: 20px;
}

.card .duration {
    bottom: 20px;
    right: 24px;
}

.card .count {
    bottom: 20px;
    right: 20px;
}

.decoration {
    position: absolute;


    top: 50px;
    right: 0;
    font-size: 40px;
    color: white;
}

.footer {

    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}