.feed-page {
    width: 70%;
    padding: 10px;
    // border: 1px solid #ccc;
    border-radius: 5px;
    // background-color: #f1f1f1;
    // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    margin-top: 40px;
}

.fid-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #f1f1f1;
}

.feed-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 20px;
        height: 20px;
    }
}

.feed-title {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    text-align: start;
    flex: 1;
    margin-left: 120px;
}

.feed-url {
    font-size: 14px;
    color: #666;
}