.ads-pricing-page {
    width: 600px;
}

.countries-pricing {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
}

.countries-pricing-input {
    display: flex;
    gap: 10px;
}

.countries-pricing-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

}