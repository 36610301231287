.splash-screen-preview {
    position: relative;
    background-color: #000000;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: fit-content;
    min-height: 300px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: left;
    padding: 25px;
}

.splash-screen-preview_content {
    // position: relative;

}

.splash-screen-preview_logo {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.splash-screen-preview_content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.splash-screen-preview_content-wrapper {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.splash-screen-preview_title {
    color: #151515;
    font-size: 14.331px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;

}

.splash-screen-preview_description {
    color: #151515;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
}