.ads-card {
    background-color: #111;
    padding: 20px;

    height: fit-content;
    min-height: 300px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: left;

    & h2 {
        font-size: 24px;
        margin-bottom: 10px;
        color: #fff;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
    }

    & p {
        font-size: 16px;
        line-height: 1.5;
        color: #fff;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
    }
}

.ads-card-logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 20px;

    & img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 50%;
    }
}